.edit {
	position: fixed; 
	z-index: 1; 
	left: 0;
	top: 0;
	width: 100%;
	height: 100%; 
	overflow: auto; 
	background-color: rgb(0,0,0);
	background-color: rgba(0,0,0,0.4);
}

.edit__content {
	width: 120rem;
	display: flex;
	flex-wrap: wrap;

	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	background-color: white;
	border: 1px solid rgba(0, 0, 0, 0.4);
	border-radius: .3rem;

	font-size: 1.6rem;

	max-height: calc(100vh - 90px);
    overflow-y: auto;
}
