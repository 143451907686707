.list-container {
	width: 135rem;
	margin: 6rem auto;

    font-size: 1.4rem;
}

.spinner {
	width: 64px;
	height: 64px;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}