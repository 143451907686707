.language {
    width: 10rem;
    height: 3rem;
    padding: .5rem;

    position: absolute;
    top: 1rem;
    left: 2rem;

    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;

    background-color: white;
}

.language:focus {
    outline: none;
}