.search-result {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	width: 85rem;	
	padding: 3rem 5rem;

	background-color: white;
	border: 1px solid rgba(0, 0, 0, 0.4);
	border-radius: .3rem;

	font-size: 1.6rem;
}

.search-result__title {
	margin-bottom: 2rem;

	text-align: center;
	font-size: 2.4rem;
}

.search-result__table {
	margin-bottom: 3rem;

	border: 1px solid rgba(0, 0, 0, 0.4);
	border-radius: .3rem;

	overflow-y: auto;
}

.list__table {
	width: 100%;

	border-collapse: collapse;
	border: 1px solid rgba(0, 0, 0, 0.4);
	border-style: hidden;
}

.list__row:nth-child(odd) {
	background-color: white;
}

.list__row:nth-child(even) {
	background-color: #f2f2f2;
}

.list__row:hover {
	background-color: #dddddd;
}

.list__head {
	padding: .5rem;

	background-color: rgb(128,194,201);
	border: 1px solid rgba(0, 0, 0, 0.4);

	font-weight: normal;
}

.list__cell {
	padding: .5rem 1rem;
	border: 1px solid rgba(0, 0, 0, 0.4);
}
