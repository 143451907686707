.signout {
	padding: .5rem 2rem;

	border: 1px solid rgba(0, 0, 0, 0.4);
	border-radius: .3rem;

	position: absolute;
	top: 1rem;
	right: 2rem;

	font-size: 1.4rem;
	cursor: pointer;
}
