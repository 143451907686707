.form__header {
	font-size: 2.4rem;
	text-align: center;
}


.form__group {
    display: flex;
    justify-content: space-between;

    position: relative;
}


.form__label {
	align-self: center;

	padding: .5rem 0;
}


.form__input {
	width: 22rem;
	padding: 0 1rem;

	border: 1px solid rgba(0, 0, 0, 0.4);
	border-radius: .3rem;
	background-color: white;
	font: 400 13.3333px Arial;
	color: black;
}

.form__input:focus {
	outline: none;
}

.form__datepicker {
	width: 22rem;
	padding: .5rem .5rem .5rem 1rem;

    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;

    font-family: sans-serif;
}

.form__datepicker:focus {
    outline: none;
}

.form__datepicker::-webkit-clear-button {
    display: none;
}

.form__datepicker::-webkit-inner-spin-button {
    display: none;
}

.form__datepicker::-webkit-calendar-picker-indicator {
    background-color: transparent;
}


.form__disabled {
	min-height: 4.2rem;
	padding: .5rem .5rem .5rem 1rem;

	background-color: #eeeeee;
	color: black;
}


.form__btn {
	margin: auto;
	padding: .5rem 2.5rem;

	min-width: 12rem;
	background-color: white;
	border: 1px solid rgba(0, 0, 0, 0.4);
	border-radius: .3rem;

	text-align: center;
	cursor: pointer;
    user-select: none;
}

.form__btn:focus {
	outline: none;
}
