.signout {
	padding: .5rem 2rem;

	border: 1px solid rgba(0, 0, 0, 0.4);
	border-radius: .3rem;

	position: absolute;
	top: 1rem;
	right: 2rem;

	font-size: 1.4rem;
	cursor: pointer;
}

.change-password-btn {
    padding: .5rem 2rem;

    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;

    position: absolute;
    top: 5rem;
    right: 2rem;

    font-size: 1.4rem;
    cursor: pointer;

    color: #000;
    background: #fff;
}

@media (max-width: 1700px) {
    .change-password-btn {
        padding: .5rem 2rem;
    
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-radius: .3rem;
    
        position: relative;
        top: 5rem;
        left: 88vw;
    
        font-size: 1.4rem;
        cursor: pointer;
    
        color: #000;
        background: #fff;
    }
  }

.list-header {
    padding: 1rem;
    position: relative;

    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;
}


.list-header__btn {
    padding: .6rem 1rem;
    position: absolute;
    right: 1rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);

    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;

    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}


.list-header__datepicker {
    width: 13rem;
    height: 3rem;
    padding: .5rem;

    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;

    font-family: sans-serif;
}

.list-header__datepicker:focus {
    outline: none;
}

.list-header__datepicker::-webkit-clear-button {
    display: none;
}

.list-header__datepicker::-webkit-inner-spin-button {
    display: none;
}

.list-header__datepicker::-webkit-calendar-picker-indicator {
    background-color: transparent;
}

.list-header__select {
    margin-left: 3rem;
    width: 13rem;
    height: 3rem;
    padding: .5rem;

    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;

    background-color: white;
}

.list-header__select:focus {
    outline: none;
}

.list-header__refresh {
    margin-left: 3rem;
    width: 9rem;
    height: 3rem;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;
    cursor: pointer;

    text-align: center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.list-header__refresh:focus {
    outline: none;
}

.datepicker_arrow {
    background: #fff;
    border: none;

    padding: 0 .5rem;

    cursor: pointer;
}
.table__row--canceled {
	background-color: #fdd !important;
}

.table__row--waiting {
	background-color: #ffc !important;
}

.table__row--confirmed {
	background-color: #dfd !important;
}

.list-main {
	margin-top: 1rem;

	border: 1px solid rgba(0, 0, 0, 0.4);
	border-radius: .3rem;
	overflow: hidden;
}

.list-main__icon {
	width: 2rem;
	height: 2rem;

	display: inline;
	cursor: pointer;
}

.list-main__icon--hidden {
	visibility: hidden;
}


.list-main__icon:not(:last-child) {
	margin-right: 1rem;
}

.confirm-cancel {
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	width: 50rem;
	height: 21rem;

	border: 1px solid rgba(0, 0, 0, 0.4);
	border-radius: .3rem;
	background-color: white;

	display: flex;
	flex-direction: column;
	justify-content: space-evenly;

	z-index: 10000;
}

.confirm-cancel__text {
	text-align: center;
	font-size: 2rem;
}

.confirm-cancel__reason {
	padding: 0 3rem;

	font-size: 1.6rem;
}

.confirm-cancel__input {
	width: 30rem !important;
}

.confirm-cancel__btn-group {
	display: flex;
	justify-content: space-evenly;
}

.confirm-cancel__btn {
	display: inline-block;

	width: 5rem;
	padding: .5rem 0;
	border: 1px solid rgba(0, 0, 0, 0.4);
	border-radius: .3rem;

	text-align: center;
	cursor: pointer;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

/*
 * Table styles
 */
table.dataTable {
	width: 100%;
	margin: 0 auto;
	clear: both;
	border-collapse: collapse; /* separate; */
	border-spacing: 0;

	border-style: hidden;
	table-layout: fixed;
	/*
	 * Header and footer styles
	 */
	/*
	 * Body styles
	 */
}
table.dataTable thead th,
table.dataTable tfoot th {
	font-weight: normal; /* bold; */
}
table.dataTable thead th,
table.dataTable thead td {
	padding: 10px 18px;
	border: 1px solid rgba(0, 0, 0, 0.4);
}
table.dataTable thead th:active,
table.dataTable thead td:active {
	outline: none;
}
table.dataTable tfoot th,
table.dataTable tfoot td {
	padding: 10px 18px 6px 18px;
	border-top: 1px solid #111111;
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
	cursor: pointer;
	*cursor: hand;
	background-repeat: no-repeat;
	background-position: center right;
}
table.dataTable thead .sorting {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAkElEQVQoz7XQMQ5AQBCF4dWQSJxC5wwax1Cq1e7BAdxD5SL+Tq/QCM1oNiJidwox0355mXnG/DrEtIQ6azioNZQxI0ykPhTQIwhCR+BmBYtlK7kLJYwWCcJA9M4qdrZrd8pPjZWPtOqdRQy320YSV17OatFC4euts6z39GYMKRPCTKY9UnPQ6P+GtMRfGtPnBCiqhAeJPmkqAAAAAElFTkSuQmCC);
}
table.dataTable thead .sorting_asc {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg==);
}
table.dataTable thead .sorting_desc {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII=);
}
table.dataTable thead .sorting_asc_disabled {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAW0lEQVQoz2NgoCm4w3Vnwh02wspK7/y6k01Ikdadx3f+37l9RxmfIsY7c4GKQHDiHUbcyhzvvIMq+3THBpci3jv7oIpAcMcdduzKEu/8vPMdDn/eiWQYBYMKAAC3ykIEuYQJUgAAAABJRU5ErkJggg==);
}
table.dataTable thead .sorting_desc_disabled {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAWUlEQVQoz2NgGAWDCtyJvPPzznc4/HknEbsy9js77vyHw313eHGZZ3PnE1TRuzuOuK1lvDMRqmzuHUZ87lO+cxuo6PEdLUIeyb7z604pYf+y3Zlwh4u2YQoAc7ZCBHH4jigAAAAASUVORK5CYII=);
}
table.dataTable tbody tr {
	background-color: white;
}
table.dataTable tbody tr.selected {
	background-color: #b0bed9;
}
table.dataTable tbody th,
table.dataTable tbody td {
	padding: 8px 10px;
	border: 1px solid rgba(0, 0, 0, 0.4);
}
table.dataTable.row-border tbody th, table.dataTable.row-border tbody td, table.dataTable.display tbody th, table.dataTable.display tbody td {
	border-top: 1px solid #dddddd;
}
table.dataTable.row-border tbody tr:first-child th,
table.dataTable.row-border tbody tr:first-child td, table.dataTable.display tbody tr:first-child th,
table.dataTable.display tbody tr:first-child td {
	border-top: none;
}
table.dataTable.cell-border tbody th, table.dataTable.cell-border tbody td {
	border-top: 1px solid #dddddd;
	border-right: 1px solid #dddddd;
}
table.dataTable.cell-border tbody tr th:first-child,
table.dataTable.cell-border tbody tr td:first-child {
	border-left: 1px solid #dddddd;
}
table.dataTable.cell-border tbody tr:first-child th,
table.dataTable.cell-border tbody tr:first-child td {
	border-top: none;
}
table.dataTable.stripe tbody tr.odd, table.dataTable.display tbody tr.odd {
	background-color: #f9f9f9;
}
table.dataTable.stripe tbody tr.odd.selected, table.dataTable.display tbody tr.odd.selected {
	background-color: #abb9d3;
}
table.dataTable.hover tbody tr:hover, table.dataTable.display tbody tr:hover {
	background-color: whitesmoke;
}
table.dataTable.hover tbody tr:hover.selected, table.dataTable.display tbody tr:hover.selected {
	background-color: #a9b7d1;
}
table.dataTable.order-column tbody tr > .sorting_1,
table.dataTable.order-column tbody tr > .sorting_2,
table.dataTable.order-column tbody tr > .sorting_3, table.dataTable.display tbody tr > .sorting_1,
table.dataTable.display tbody tr > .sorting_2,
table.dataTable.display tbody tr > .sorting_3 {
	background-color: #f9f9f9;
}
table.dataTable.order-column tbody tr.selected > .sorting_1,
table.dataTable.order-column tbody tr.selected > .sorting_2,
table.dataTable.order-column tbody tr.selected > .sorting_3, table.dataTable.display tbody tr.selected > .sorting_1,
table.dataTable.display tbody tr.selected > .sorting_2,
table.dataTable.display tbody tr.selected > .sorting_3 {
	background-color: #acbad4;
}
table.dataTable.display tbody tr.odd > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
	background-color: #f1f1f1;
}
table.dataTable.display tbody tr.odd > .sorting_2, table.dataTable.order-column.stripe tbody tr.odd > .sorting_2 {
	background-color: #f3f3f3;
}
table.dataTable.display tbody tr.odd > .sorting_3, table.dataTable.order-column.stripe tbody tr.odd > .sorting_3 {
	background-color: whitesmoke;
}
table.dataTable.display tbody tr.odd.selected > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_1 {
	background-color: #a6b3cd;
}
table.dataTable.display tbody tr.odd.selected > .sorting_2, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_2 {
	background-color: #a7b5ce;
}
table.dataTable.display tbody tr.odd.selected > .sorting_3, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_3 {
	background-color: #a9b6d0;
}
table.dataTable.display tbody tr.even > .sorting_1, table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
	background-color: #f9f9f9;
}
table.dataTable.display tbody tr.even > .sorting_2, table.dataTable.order-column.stripe tbody tr.even > .sorting_2 {
	background-color: #fbfbfb;
}
table.dataTable.display tbody tr.even > .sorting_3, table.dataTable.order-column.stripe tbody tr.even > .sorting_3 {
	background-color: #fdfdfd;
}
table.dataTable.display tbody tr.even.selected > .sorting_1, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_1 {
	background-color: #acbad4;
}
table.dataTable.display tbody tr.even.selected > .sorting_2, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_2 {
	background-color: #adbbd6;
}
table.dataTable.display tbody tr.even.selected > .sorting_3, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_3 {
	background-color: #afbdd8;
}
table.dataTable.display tbody tr:hover > .sorting_1, table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
	background-color: #eaeaea;
}
table.dataTable.display tbody tr:hover > .sorting_2, table.dataTable.order-column.hover tbody tr:hover > .sorting_2 {
	background-color: #ebebeb;
}
table.dataTable.display tbody tr:hover > .sorting_3, table.dataTable.order-column.hover tbody tr:hover > .sorting_3 {
	background-color: #eeeeee;
}
table.dataTable.display tbody tr:hover.selected > .sorting_1, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1 {
	background-color: #a1aec7;
}
table.dataTable.display tbody tr:hover.selected > .sorting_2, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2 {
	background-color: #a2afc8;
}
table.dataTable.display tbody tr:hover.selected > .sorting_3, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3 {
	background-color: #a4b2cb;
}
table.dataTable.no-footer {
	/* border-bottom: 1px solid #111111; */
}
table.dataTable.nowrap th, table.dataTable.nowrap td {
	white-space: nowrap;
}
table.dataTable.compact thead th,
table.dataTable.compact thead td {
	padding: 4px 17px 4px 4px;
}
table.dataTable.compact tfoot th,
table.dataTable.compact tfoot td {
	padding: 4px;
}
table.dataTable.compact tbody th,
table.dataTable.compact tbody td {
	padding: 4px;
}
table.dataTable th.dt-left,
table.dataTable td.dt-left {
	text-align: left;
}
table.dataTable th.dt-center,
table.dataTable td.dt-center,
table.dataTable td.dataTables_empty {
	text-align: center;
}
table.dataTable th.dt-right,
table.dataTable td.dt-right {
	text-align: right;
}
table.dataTable th.dt-justify,
table.dataTable td.dt-justify {
	text-align: justify;
}
table.dataTable th.dt-nowrap,
table.dataTable td.dt-nowrap {
	white-space: nowrap;
}
table.dataTable thead th.dt-head-left,
table.dataTable thead td.dt-head-left,
table.dataTable tfoot th.dt-head-left,
table.dataTable tfoot td.dt-head-left {
	text-align: left;
}
table.dataTable thead th.dt-head-center,
table.dataTable thead td.dt-head-center,
table.dataTable tfoot th.dt-head-center,
table.dataTable tfoot td.dt-head-center {
	text-align: center;
}
table.dataTable thead th.dt-head-right,
table.dataTable thead td.dt-head-right,
table.dataTable tfoot th.dt-head-right,
table.dataTable tfoot td.dt-head-right {
	text-align: right;
}
table.dataTable thead th.dt-head-justify,
table.dataTable thead td.dt-head-justify,
table.dataTable tfoot th.dt-head-justify,
table.dataTable tfoot td.dt-head-justify {
	text-align: justify;
}
table.dataTable thead th.dt-head-nowrap,
table.dataTable thead td.dt-head-nowrap,
table.dataTable tfoot th.dt-head-nowrap,
table.dataTable tfoot td.dt-head-nowrap {
	white-space: nowrap;
}
table.dataTable tbody th.dt-body-left,
table.dataTable tbody td.dt-body-left {
	text-align: left;
}
table.dataTable tbody th.dt-body-center,
table.dataTable tbody td.dt-body-center {
	text-align: center;
}
table.dataTable tbody th.dt-body-right,
table.dataTable tbody td.dt-body-right {
	text-align: right;
}
table.dataTable tbody th.dt-body-justify,
table.dataTable tbody td.dt-body-justify {
	text-align: justify;
}
table.dataTable tbody th.dt-body-nowrap,
table.dataTable tbody td.dt-body-nowrap {
	white-space: nowrap;
}

table.dataTable,
table.dataTable th,
table.dataTable td {
	box-sizing: content-box;
}

/*
 * Control feature layout
 */
.dataTables_wrapper {
	position: relative;
	clear: both;
	*zoom: 1;
	zoom: 1;
}
.dataTables_wrapper .dataTables_length {
	float: left;
}
.dataTables_wrapper .dataTables_filter {
	float: right;
	text-align: right;
}
.dataTables_wrapper .dataTables_filter input {
	margin-left: 0.5em;
}
.dataTables_wrapper .dataTables_info {
	clear: both;
	float: left;
	padding-top: 0.755em;
}
.dataTables_wrapper .dataTables_paginate {
	float: right;
	text-align: right;
	padding-top: 0.25em;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
	box-sizing: border-box;
	display: inline-block;
	min-width: 1.5em;
	padding: 0.5em 1em;
	margin-left: 2px;
	text-align: center;
	text-decoration: none !important;
	cursor: pointer;
	*cursor: hand;
	color: #333333 !important;
	border: 1px solid transparent;
	border-radius: 2px;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
	color: #333333 !important;
	border: 1px solid #979797;
	background-color: white;
	/* Chrome,Safari4+ */
	/* Chrome10+,Safari5.1+ */
	/* FF3.6+ */
	/* IE10+ */
	/* Opera 11.10+ */
	background: linear-gradient(to bottom, white 0%, gainsboro 100%);
	/* W3C */
}
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
	cursor: default;
	color: #666 !important;
	border: 1px solid transparent;
	background: transparent;
	box-shadow: none;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
	color: white !important;
	border: 1px solid #111111;
	background-color: #585858;
	/* Chrome,Safari4+ */
	/* Chrome10+,Safari5.1+ */
	/* FF3.6+ */
	/* IE10+ */
	/* Opera 11.10+ */
	background: linear-gradient(to bottom, #585858 0%, #111111 100%);
	/* W3C */
}
.dataTables_wrapper .dataTables_paginate .paginate_button:active {
	outline: none;
	background-color: #2b2b2b;
	/* Chrome,Safari4+ */
	/* Chrome10+,Safari5.1+ */
	/* FF3.6+ */
	/* IE10+ */
	/* Opera 11.10+ */
	background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
	/* W3C */
	box-shadow: inset 0 0 3px #111;
}
.dataTables_wrapper .dataTables_paginate .ellipsis {
	padding: 0 1em;
}
.dataTables_wrapper .dataTables_processing {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 40px;
	margin-left: -50%;
	margin-top: -25px;
	padding-top: 20px;
	text-align: center;
	font-size: 1.2em;
	background-color: white;
	background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
}
.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
	color: #333333;
}
.dataTables_wrapper .dataTables_scroll {
	clear: both;
}
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
	*margin-top: -1px;
	-webkit-overflow-scrolling: touch;
}
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td {
	vertical-align: middle;
}
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th > div.dataTables_sizing,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td > div.dataTables_sizing, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th > div.dataTables_sizing,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td > div.dataTables_sizing {
	height: 0;
	overflow: hidden;
	margin: 0 !important;
	padding: 0 !important;
}
.dataTables_wrapper.no-footer .dataTables_scrollBody {
	border-bottom: 1px solid #111111;
}
.dataTables_wrapper.no-footer div.dataTables_scrollHead table.dataTable,
.dataTables_wrapper.no-footer div.dataTables_scrollBody > table {
	border-bottom: none;
}
.dataTables_wrapper:after {
	visibility: hidden;
	display: block;
	content: "";
	clear: both;
	height: 0;
}

@media screen and (max-width: 767px) {
	.dataTables_wrapper .dataTables_info,
	.dataTables_wrapper .dataTables_paginate {
		float: none;
		text-align: center;
	}
	.dataTables_wrapper .dataTables_paginate {
		margin-top: 0.5em;
	}
}
@media screen and (max-width: 640px) {
	.dataTables_wrapper .dataTables_length,
	.dataTables_wrapper .dataTables_filter {
		float: none;
		text-align: center;
	}
	.dataTables_wrapper .dataTables_filter {
		margin-top: 0.5em;
	}
}

.dt-buttons {
	display: inline-block;
	margin-left: 3rem;
}

.dt-button.buttons-print {
	width: 9rem;
	height: 3rem;

	background-color: white;
	border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;
    cursor: pointer;
}

.dt-button.buttons-print:focus {
	outline: none;
}

@media print  {
	table.dataTable {
		overflow-wrap: break-word; 
		-webkit-hyphens: auto; 
		    -ms-hyphens: auto; 
		        hyphens: auto;
	} 
}
	
.call {
	position: fixed; 
	z-index: 1; 
	left: 0;
	top: 0;
	width: 100%;
	height: 100%; 
	overflow: auto; 
	background-color: rgb(0,0,0);
	background-color: rgba(0,0,0,0.4);
}

.call__content{
	width: 120rem;
	display: flex;
	flex-wrap: wrap;

	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);

	background-color: white;
	border: 1px solid rgba(0, 0, 0, 0.4);
	border-radius: .3rem;

	font-size: 1.6rem;

	max-height: calc(100vh - 90px);
    overflow-y: auto;
}


.call-search {
	width: 40%;
	height: 45rem;
	position: relative;

	border-right: 1px solid rgba(0, 0, 0, 0.4);
}

.call-search__form {
	width: 35rem;
	height: 35rem;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	position: absolute;
	top: calc(50% - 1rem);
	left: 50%;
	-webkit-transform: translate(-50%,-50%);
	        transform: translate(-50%,-50%);
}

.call-address-edit__icon {
	width: 2rem;
	height: 2rem;

	position: absolute;
	top: 50%;
	left: 106%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.form__msg {
	position: absolute;
	top: 33rem;
	bottom: -.5rem;
	left: 50%;
	-webkit-transform: translate(-50%, 120%);
	        transform: translate(-50%, 120%);

	text-align: center;
	font-size: 1.4rem;
}

.form__msg-link {
	font-size: 1.6rem;
	margin-bottom: .5rem;
	cursor: pointer;
}

.call-map {
	width: 60%;
	height: 45rem;
	position: relative;
}

.call-map__close {
	cursor: pointer;
	position: -webkit-sticky;
	position: sticky;
	top: .5rem;
	left: 100%;
	width: 2rem;

	font-size: 2rem;
	font-weight: bold;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.call-map__legend {
	position: absolute;
	top: 41rem;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);

	width: 19.5rem;
	display: flex;
    justify-content: space-between;
}

#call-map__map {
	width: 55rem;
	height: 35rem;

	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.ol-control {
	font-size: 1rem;
}

.ol-control button:focus  {
	background-color: rgba(0,60,136,0.5) !important;
	outline: none;
}

.ol-control button:hover {
	background-color: rgba(0,60,136,0.7) !important;
}

.map__icon {
	width: 2rem;
	height: 2rem;
}

.call-submit {
	width: 100%;
	height: 30rem;

	position: relative;
	border-top: 1px solid rgba(0, 0, 0, 0.4);
}

.call-submit__form {
	width: 70rem;
	height: 23rem;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%,-50%);
	        transform: translate(-50%,-50%);
}

.call-submit__input {
	width: 57rem !important;
}

.call-submit__select {
	width: 22rem !important;
	height: 100%;

	background-color: white;
}

.search-result {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);

	width: 85rem;	
	padding: 3rem 5rem;

	background-color: white;
	border: 1px solid rgba(0, 0, 0, 0.4);
	border-radius: .3rem;

	font-size: 1.6rem;
}

.search-result__title {
	margin-bottom: 2rem;

	text-align: center;
	font-size: 2.4rem;
}

.search-result__table {
	margin-bottom: 3rem;

	border: 1px solid rgba(0, 0, 0, 0.4);
	border-radius: .3rem;

	overflow-y: auto;
}

.list__table {
	width: 100%;

	border-collapse: collapse;
	border: 1px solid rgba(0, 0, 0, 0.4);
	border-style: hidden;
}

.list__row:nth-child(odd) {
	background-color: white;
}

.list__row:nth-child(even) {
	background-color: #f2f2f2;
}

.list__row:hover {
	background-color: #dddddd;
}

.list__head {
	padding: .5rem;

	background-color: rgb(128,194,201);
	border: 1px solid rgba(0, 0, 0, 0.4);

	font-weight: normal;
}

.list__cell {
	padding: .5rem 1rem;
	border: 1px solid rgba(0, 0, 0, 0.4);
}

.edit-address {
	width: 45%;
	height: 40rem;

	position: relative;

	border-right: 1px solid rgba(0, 0, 0, 0.4);
    font-size: 1.4rem;
}

.edit-address_form {
	width: 35rem;
	height: 30rem;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}
.edit-address-map {
	width: 55%;
	position: relative;
}

.edit-address-map__close {
	position: absolute;
	right: 1rem;
	top: .5rem;

	font-size: 2rem;
	font-weight: bold;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

#edit-address-map__map {
	width: 40rem;
	height: 30rem;

	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.edit-address__help {
	width: 40rem;

	position: absolute;
	top: calc(50% + 16rem);
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);

	font-size: 1.6rem;
	text-align: center;
}
.edit-address-container {
	width: 100rem;
	display: flex;
	flex-wrap: wrap;

	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);

	background-color: white;
	border: 1px solid rgba(0, 0, 0, 0.4);
	border-radius: .3rem;
}
.edit {
	position: fixed; 
	z-index: 1; 
	left: 0;
	top: 0;
	width: 100%;
	height: 100%; 
	overflow: auto; 
	background-color: rgb(0,0,0);
	background-color: rgba(0,0,0,0.4);
}

.edit__content {
	width: 120rem;
	display: flex;
	flex-wrap: wrap;

	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);

	background-color: white;
	border: 1px solid rgba(0, 0, 0, 0.4);
	border-radius: .3rem;

	font-size: 1.6rem;

	max-height: calc(100vh - 90px);
    overflow-y: auto;
}

#edit-map__map {
	width: 55rem;
	height: 35rem;

	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.copy__window {
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	width: 50rem;
	height: 21rem;

	border: 1px solid rgba(0, 0, 0, 0.4);
	border-radius: .3rem;
	background-color: white;

	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}

.copy__select {
	width: 22rem;
}

.copy__jcse {
	justify-content: space-evenly !important;
}

.copy__jcse .form__btn {
	margin: unset;
}

.registration {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.registration__content {
    width: 125rem;
    display: flex;
    flex-wrap: wrap;

    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;

    font-size: 1.6rem;

    max-height: calc(100vh - 90px);
    overflow-y: auto;
    height: 62rem;
}

.registration-map {
	width: 60%;
	position: relative;
}

.registration-map__close {
	position: absolute;
	right: 1rem;
	top: .5rem;

	font-size: 2rem;
	font-weight: bold;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	cursor: pointer;
}

#registration-map__map {
	width: 60rem;
	height: 45rem;

	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.registration__help {
	width: 60rem;

	position: absolute;
	top: calc(50% + 24rem);
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);

	font-size: 1.6rem;
	text-align: center;
}
.registration-submit {
	width: 40%;
	position: relative;

	border-right: 1px solid rgba(0, 0, 0, 0.4);
}

.registration-submit__form {
	width: 35rem;
	height: 54rem;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%,-50%);
	        transform: translate(-50%,-50%);

	font-size: 1.6rem;
}

.registration-sex__input-container {
	width: 22rem;
}

.registration-sex__input {
	width: 50%;
	position: relative;
	display: inline-flex;
}

.registration-radio {
	height: 1px;
	position: absolute;
	top: 1.7em;
	left: .4rem;
}

.registration-radio:focus {
	outline: none
}

.registration-radio-button {
	height: 2rem;
    width: 2rem;
    border: 2px solid #3875BF;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: 0;
    top: .4rem;
}

.registration-radio-button::after {
    content: "";
    display: block;
    height: 1.1rem;
    width: 1.1rem;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: #3875BF;
    opacity: 0;
    transition: opacity .2s;
}

.registration-sex__label {
    padding: .5rem 0;
    cursor: pointer;
    position: relative;
    padding-left: 2.5rem;
}

.registration-radio:checked ~ .registration-sex__label .registration-radio-button::after {
	opacity: 1;
}
.attachment {
	width: 45rem;
	height: 32rem;

	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);

	border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;
    background-color: white;

    font-size: 1.4rem;
}

.attachment_form {
	width: 36rem;
	height: 26rem;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.attachment__header {
	font-size: 1.8rem;
    text-align: center;
    margin-bottom: 2rem;
}

.attachment__header p:not(:last-child) {
	margin-bottom: 1rem;
}
.brigade {
	position: fixed; 
	z-index: 1; 
	left: 0;
	top: 0;
	width: 100%;
	height: 100%; 
	overflow: auto; 
	background-color: rgb(0,0,0);
	background-color: rgba(0,0,0,0.4);
}

.brigade__content {
	width: 90rem;

	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);

	border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;
    background-color: white;

    font-size: 1.6rem;

	z-index: 100;
	
	max-height: calc(100vh - 90px);
    overflow-y: auto;
}

.brigade__table {
	margin-top: 1.6rem;

	border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

.brigade__table table {
	margin-top: -1px;
	border-top: 1px solid rgba(0, 0, 0, 0.4);
}

.brigade__btns {
	display: flex;
    justify-content: space-evenly;
    position: relative;

    margin: 1.5rem auto;
    width: 70rem;
}

.confirm-delete {
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	width: 40rem;
	height: 15rem;

	border: 1px solid rgba(0, 0, 0, 0.4);
	border-radius: .3rem;
	background-color: white;

	display: flex;
	flex-direction: column;
	justify-content: space-evenly;

	z-index: 10000;
}
.create-brigade {
	width: 48rem;
	height: 40rem;
	z-index: 1000;

	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);

	background-color: white;
	border: 1px solid rgba(0, 0, 0, 0.4);
	border-radius: .3rem;

	font-size: 1.6rem;
}

.create-brigade__form {
	width: 35rem;
	height: 32rem;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%,-50%);
	        transform: translate(-50%,-50%);
}

.brigade__input {
	width: 22rem !important;
}
.list-container {
	width: 135rem;
	margin: 6rem auto;

    font-size: 1.4rem;
}

.spinner {
	width: 64px;
	height: 64px;

	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}
.form__header {
	font-size: 2.4rem;
	text-align: center;
}


.form__group {
    display: flex;
    justify-content: space-between;

    position: relative;
}


.form__label {
	align-self: center;

	padding: .5rem 0;
}


.form__input {
	width: 22rem;
	padding: 0 1rem;

	border: 1px solid rgba(0, 0, 0, 0.4);
	border-radius: .3rem;
	background-color: white;
	font: 400 13.3333px Arial;
	color: black;
}

.form__input:focus {
	outline: none;
}

.form__datepicker {
	width: 22rem;
	padding: .5rem .5rem .5rem 1rem;

    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;

    font-family: sans-serif;
}

.form__datepicker:focus {
    outline: none;
}

.form__datepicker::-webkit-clear-button {
    display: none;
}

.form__datepicker::-webkit-inner-spin-button {
    display: none;
}

.form__datepicker::-webkit-calendar-picker-indicator {
    background-color: transparent;
}


.form__disabled {
	min-height: 4.2rem;
	padding: .5rem .5rem .5rem 1rem;

	background-color: #eeeeee;
	color: black;
}


.form__btn {
	margin: auto;
	padding: .5rem 2.5rem;

	min-width: 12rem;
	background-color: white;
	border: 1px solid rgba(0, 0, 0, 0.4);
	border-radius: .3rem;

	text-align: center;
	cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.form__btn:focus {
	outline: none;
}

.change-password {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}

.change-password__content {
    position: absolute;
    z-index: 2;
    top: 0;
    left: calc((100% - 450px) / 2);

    width: 450px;
    background: #fff;

    padding: 20px 10px;
    margin: 5% auto;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;
    font-size: 1.4rem;
}

.change-password__form {
    padding: 20px 10px 0;
}

.change-password .form__group {
    padding-bottom: 20px;
}

.change-password__footer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.change-password__close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #000;
    background: #fff;
    padding: 0 1px 2px;
    cursor: pointer;
}

.popup {
	padding: 3rem 4rem;

	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);

	border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;

    background-color: white;
    z-index: 1000;

    font-size: 2rem;
    text-align: center;
}
.language {
    width: 10rem;
    height: 3rem;
    padding: .5rem;

    position: absolute;
    top: 1rem;
    left: 2rem;

    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;

    background-color: white;
}

.language:focus {
    outline: none;
}
.signin {
	width: 45rem;
	height: 30rem;

	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);

	border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;

    font-size: 1.4rem;
}

.signin__logo {
	height: 8rem;

	margin: auto;
}

.signin__form {
	width: 36rem;
	height: 23rem;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	position: absolute;
	top: calc(50% - 1rem);
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.sign-footer {
	position: absolute;
	left: 50%;
	bottom: 1rem;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
}

.sign-footer__link {
	text-decoration: none;
	color: black;
}

.btn--blue {
	padding: .6rem 2.5rem;
	background-color: #518ed0;
    color: #fff;

    border: none;
    transition: background-color .4s;
}

.btn--blue:hover {
	background-color: #85c6f4;
}

.signup {
	width: 45rem;
	height: 51rem;

	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);

	border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;

    font-size: 1.4rem;
}

.signup__form {
	width: 36rem;
	height: 42rem;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.singup__error-notification {
	width: 33rem;
	position: relative;
	left: 45rem;

	border: none;
	border-radius: 5px;

	padding: 15px 20px;
	margin: 0 1rem;

	color: #D8000C;
	background-color: #FFBABA;
}

.singup__error-notification::before {
	content: '\2716';

    display: inline-block;
    width: 2rem;
    height: 2rem;

    padding: 0.2rem 0.39rem;
    margin-right: 1rem;
	background-color: #D8000C;
	color: #FFBABA;
	border-radius: 50%;
}

*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
	font-family: "Times New Roman", Times, serif;
	font-size: 62.5%;	
}

body {
	box-sizing: border-box;
}

