.confirm-cancel {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 50rem;
	height: 21rem;

	border: 1px solid rgba(0, 0, 0, 0.4);
	border-radius: .3rem;
	background-color: white;

	display: flex;
	flex-direction: column;
	justify-content: space-evenly;

	z-index: 10000;
}

.confirm-cancel__text {
	text-align: center;
	font-size: 2rem;
}

.confirm-cancel__reason {
	padding: 0 3rem;

	font-size: 1.6rem;
}

.confirm-cancel__input {
	width: 30rem !important;
}

.confirm-cancel__btn-group {
	display: flex;
	justify-content: space-evenly;
}

.confirm-cancel__btn {
	display: inline-block;

	width: 5rem;
	padding: .5rem 0;
	border: 1px solid rgba(0, 0, 0, 0.4);
	border-radius: .3rem;

	text-align: center;
	cursor: pointer;
	user-select: none;
}
