.change-password {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}

.change-password__content {
    position: absolute;
    z-index: 2;
    top: 0;
    left: calc((100% - 450px) / 2);

    width: 450px;
    background: #fff;

    padding: 20px 10px;
    margin: 5% auto;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;
    font-size: 1.4rem;
}

.change-password__form {
    padding: 20px 10px 0;
}

.change-password .form__group {
    padding-bottom: 20px;
}

.change-password__footer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.change-password__close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #000;
    background: #fff;
    padding: 0 1px 2px;
    cursor: pointer;
}
