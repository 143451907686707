.brigade {
	position: fixed; 
	z-index: 1; 
	left: 0;
	top: 0;
	width: 100%;
	height: 100%; 
	overflow: auto; 
	background-color: rgb(0,0,0);
	background-color: rgba(0,0,0,0.4);
}

.brigade__content {
	width: 90rem;

	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;
    background-color: white;

    font-size: 1.6rem;

	z-index: 100;
	
	max-height: calc(100vh - 90px);
    overflow-y: auto;
}

.brigade__table {
	margin-top: 1.6rem;

	border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

.brigade__table table {
	margin-top: -1px;
	border-top: 1px solid rgba(0, 0, 0, 0.4);
}

.brigade__btns {
	display: flex;
    justify-content: space-evenly;
    position: relative;

    margin: 1.5rem auto;
    width: 70rem;
}

.confirm-delete {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 40rem;
	height: 15rem;

	border: 1px solid rgba(0, 0, 0, 0.4);
	border-radius: .3rem;
	background-color: white;

	display: flex;
	flex-direction: column;
	justify-content: space-evenly;

	z-index: 10000;
}