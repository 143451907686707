.create-brigade {
	width: 48rem;
	height: 40rem;
	z-index: 1000;

	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	background-color: white;
	border: 1px solid rgba(0, 0, 0, 0.4);
	border-radius: .3rem;

	font-size: 1.6rem;
}

.create-brigade__form {
	width: 35rem;
	height: 32rem;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}

.brigade__input {
	width: 22rem !important;
}