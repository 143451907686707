.edit-address-map {
	width: 55%;
	position: relative;
}

.edit-address-map__close {
	position: absolute;
	right: 1rem;
	top: .5rem;

	font-size: 2rem;
	font-weight: bold;
	user-select: none;
}

#edit-address-map__map {
	width: 40rem;
	height: 30rem;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.edit-address__help {
	width: 40rem;

	position: absolute;
	top: calc(50% + 16rem);
	left: 50%;
	transform: translateX(-50%);

	font-size: 1.6rem;
	text-align: center;
}