.signin {
	width: 45rem;
	height: 30rem;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;

    font-size: 1.4rem;
}

.signin__logo {
	height: 8rem;

	margin: auto;
}

.signin__form {
	width: 36rem;
	height: 23rem;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	position: absolute;
	top: calc(50% - 1rem);
	left: 50%;
	transform: translate(-50%, -50%);
}

.sign-footer {
	position: absolute;
	left: 50%;
	bottom: 1rem;
	transform: translateX(-50%);
}

.sign-footer__link {
	text-decoration: none;
	color: black;
}

.btn--blue {
	padding: .6rem 2.5rem;
	background-color: #518ed0;
    color: #fff;

    border: none;
    transition: background-color .4s;
}

.btn--blue:hover {
	background-color: #85c6f4;
}
