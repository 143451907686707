.attachment {
	width: 45rem;
	height: 32rem;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;
    background-color: white;

    font-size: 1.4rem;
}

.attachment_form {
	width: 36rem;
	height: 26rem;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.attachment__header {
	font-size: 1.8rem;
    text-align: center;
    margin-bottom: 2rem;
}

.attachment__header p:not(:last-child) {
	margin-bottom: 1rem;
}