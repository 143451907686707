.copy__window {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 50rem;
	height: 21rem;

	border: 1px solid rgba(0, 0, 0, 0.4);
	border-radius: .3rem;
	background-color: white;

	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}

.copy__select {
	width: 22rem;
}

.copy__jcse {
	justify-content: space-evenly !important;
}

.copy__jcse .form__btn {
	margin: unset;
}