.registration-map {
	width: 60%;
	position: relative;
}

.registration-map__close {
	position: absolute;
	right: 1rem;
	top: .5rem;

	font-size: 2rem;
	font-weight: bold;
	user-select: none;
	cursor: pointer;
}

#registration-map__map {
	width: 60rem;
	height: 45rem;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.registration__help {
	width: 60rem;

	position: absolute;
	top: calc(50% + 24rem);
	left: 50%;
	transform: translateX(-50%);

	font-size: 1.6rem;
	text-align: center;
}