.registration-submit {
	width: 40%;
	position: relative;

	border-right: 1px solid rgba(0, 0, 0, 0.4);
}

.registration-submit__form {
	width: 35rem;
	height: 54rem;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);

	font-size: 1.6rem;
}

.registration-sex__input-container {
	width: 22rem;
}

.registration-sex__input {
	width: 50%;
	position: relative;
	display: inline-flex;
}

.registration-radio {
	height: 1px;
	position: absolute;
	top: 1.7em;
	left: .4rem;
}

.registration-radio:focus {
	outline: none
}

.registration-radio-button {
	height: 2rem;
    width: 2rem;
    border: 2px solid #3875BF;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: 0;
    top: .4rem;
}

.registration-radio-button::after {
    content: "";
    display: block;
    height: 1.1rem;
    width: 1.1rem;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #3875BF;
    opacity: 0;
    transition: opacity .2s;
}

.registration-sex__label {
    padding: .5rem 0;
    cursor: pointer;
    position: relative;
    padding-left: 2.5rem;
}

.registration-radio:checked ~ .registration-sex__label .registration-radio-button::after {
	opacity: 1;
}