.list-header {
    padding: 1rem;
    position: relative;

    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;
}


.list-header__btn {
    padding: .6rem 1rem;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);

    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;

    cursor: pointer;
    user-select: none;
}


.list-header__datepicker {
    width: 13rem;
    height: 3rem;
    padding: .5rem;

    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;

    font-family: sans-serif;
}

.list-header__datepicker:focus {
    outline: none;
}

.list-header__datepicker::-webkit-clear-button {
    display: none;
}

.list-header__datepicker::-webkit-inner-spin-button {
    display: none;
}

.list-header__datepicker::-webkit-calendar-picker-indicator {
    background-color: transparent;
}

.list-header__select {
    margin-left: 3rem;
    width: 13rem;
    height: 3rem;
    padding: .5rem;

    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;

    background-color: white;
}

.list-header__select:focus {
    outline: none;
}

.list-header__refresh {
    margin-left: 3rem;
    width: 9rem;
    height: 3rem;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;
    cursor: pointer;

    text-align: center;
    user-select: none;
}

.list-header__refresh:focus {
    outline: none;
}

.datepicker_arrow {
    background: #fff;
    border: none;

    padding: 0 .5rem;

    cursor: pointer;
}