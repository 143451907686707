.call-submit {
	width: 100%;
	height: 30rem;

	position: relative;
	border-top: 1px solid rgba(0, 0, 0, 0.4);
}

.call-submit__form {
	width: 70rem;
	height: 23rem;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}

.call-submit__input {
	width: 57rem !important;
}

.call-submit__select {
	width: 22rem !important;
	height: 100%;

	background-color: white;
}
