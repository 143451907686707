.edit-address-container {
	width: 100rem;
	display: flex;
	flex-wrap: wrap;

	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	background-color: white;
	border: 1px solid rgba(0, 0, 0, 0.4);
	border-radius: .3rem;
}