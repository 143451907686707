.call-map {
	width: 60%;
	height: 45rem;
	position: relative;
}

.call-map__close {
	cursor: pointer;
	position: sticky;
	top: .5rem;
	left: 100%;
	width: 2rem;

	font-size: 2rem;
	font-weight: bold;
	user-select: none;
}

.call-map__legend {
	position: absolute;
	top: 41rem;
	left: 50%;
	transform: translateX(-50%);

	width: 19.5rem;
	display: flex;
    justify-content: space-between;
}

#call-map__map {
	width: 55rem;
	height: 35rem;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.ol-control {
	font-size: 1rem;
}

.ol-control button:focus  {
	background-color: rgba(0,60,136,0.5) !important;
	outline: none;
}

.ol-control button:hover {
	background-color: rgba(0,60,136,0.7) !important;
}

.map__icon {
	width: 2rem;
	height: 2rem;
}
