.edit-address {
	width: 45%;
	height: 40rem;

	position: relative;

	border-right: 1px solid rgba(0, 0, 0, 0.4);
    font-size: 1.4rem;
}

.edit-address_form {
	width: 35rem;
	height: 30rem;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}