.popup {
	padding: 3rem 4rem;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;

    background-color: white;
    z-index: 1000;

    font-size: 2rem;
    text-align: center;
}