.signup {
	width: 45rem;
	height: 51rem;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;

    font-size: 1.4rem;
}

.signup__form {
	width: 36rem;
	height: 42rem;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.singup__error-notification {
	width: 33rem;
	position: relative;
	left: 45rem;

	border: none;
	border-radius: 5px;

	padding: 15px 20px;
	margin: 0 1rem;

	color: #D8000C;
	background-color: #FFBABA;
}

.singup__error-notification::before {
	content: '\2716';

    display: inline-block;
    width: 2rem;
    height: 2rem;

    padding: 0.2rem 0.39rem;
    margin-right: 1rem;
	background-color: #D8000C;
	color: #FFBABA;
	border-radius: 50%;
}
