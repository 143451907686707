*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
	font-family: "Times New Roman", Times, serif;
	font-size: 62.5%;	
}

body {
	box-sizing: border-box;
}
