.change-password-btn {
    padding: .5rem 2rem;

    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: .3rem;

    position: absolute;
    top: 5rem;
    right: 2rem;

    font-size: 1.4rem;
    cursor: pointer;

    color: #000;
    background: #fff;
}

@media (max-width: 1700px) {
    .change-password-btn {
        padding: .5rem 2rem;
    
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-radius: .3rem;
    
        position: relative;
        top: 5rem;
        left: 88vw;
    
        font-size: 1.4rem;
        cursor: pointer;
    
        color: #000;
        background: #fff;
    }
  }
