.table__row--canceled {
	background-color: #fdd !important;
}

.table__row--waiting {
	background-color: #ffc !important;
}

.table__row--confirmed {
	background-color: #dfd !important;
}

.list-main {
	margin-top: 1rem;

	border: 1px solid rgba(0, 0, 0, 0.4);
	border-radius: .3rem;
	overflow: hidden;
}

.list-main__icon {
	width: 2rem;
	height: 2rem;

	display: inline;
	cursor: pointer;
}

.list-main__icon--hidden {
	visibility: hidden;
}


.list-main__icon:not(:last-child) {
	margin-right: 1rem;
}
