.call-search {
	width: 40%;
	height: 45rem;
	position: relative;

	border-right: 1px solid rgba(0, 0, 0, 0.4);
}

.call-search__form {
	width: 35rem;
	height: 35rem;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	position: absolute;
	top: calc(50% - 1rem);
	left: 50%;
	transform: translate(-50%,-50%);
}

.call-address-edit__icon {
	width: 2rem;
	height: 2rem;

	position: absolute;
	top: 50%;
	left: 106%;
	transform: translate(-50%, -50%);
}

.form__msg {
	position: absolute;
	top: 33rem;
	bottom: -.5rem;
	left: 50%;
	transform: translate(-50%, 120%);

	text-align: center;
	font-size: 1.4rem;
}

.form__msg-link {
	font-size: 1.6rem;
	margin-bottom: .5rem;
	cursor: pointer;
}
